import { Icons } from '../../icons';
import React from 'react';
import { Typography } from '../Typography';
import { Flex } from '../Flex';
import { Spinner } from '../Loaders';
import * as styles from './FileUploadContent.css';

type IProps = {
  inProgressFilePath?: string;
  error?: string;
};

export const FileUploadContent = ({ inProgressFilePath: filePath, error }: IProps) => {
  if (error) {
    return (
      <Flex.Container flexDirection="column" justifyContent="center" alignItems="center" gap="4u">
        {/* Todo: Match design with this icon */}
        {/* <div className={styles.errorIconContainer}> */}
        <Icons.CloseCircle className={styles.errorIcon} />
        {/* </div> */}
        <Typography fontWeight="regular" font="body1" margin="none" as="span" style={{ textAlign: 'center' }}>
          {error}
        </Typography>
        <Typography fontWeight="semibold" color="highlight" font="body1" as="span">
          Upload
        </Typography>
      </Flex.Container>
    );
  }

  if (filePath) {
    return (
      <Flex.Container flexDirection="column" justifyContent="center" gap="4u" alignItems="center">
        <Spinner />
        <Typography fontWeight="semibold" font="body1" margin="none" as="span">
          {filePath}
        </Typography>
        <Typography fontWeight="regular" font="caption1" as="span">
          Upload in progress
        </Typography>
      </Flex.Container>
    );
  }

  return (
    <Flex.Container flexDirection="column" justifyContent="center" gap="4u" alignItems="center">
      <Icons.UploadCloud className={styles.uploadIcon} />
      <Flex.Container>
        <Typography fontWeight="semibold" font="body1" margin="none" as="span">
          Drag a file or&nbsp;
        </Typography>
        <Typography fontWeight="semibold" color="highlight" font="body1" as="span">
          click to upload
        </Typography>
      </Flex.Container>
    </Flex.Container>
  );
};
